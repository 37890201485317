.container {
    color: white;
    align-self: center;
    justify-self: center;
    width: 1234px;
    height: 730px;
    margin-top: 50px;
    background-image: url("../../assets/background.jpg");
    
    div {
        margin: 200px;
        background-color: rgba(0, 0, 0, 0.452);
        padding: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}