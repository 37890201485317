.container {
    height: 8vh;
    width: 100%;
    background-color: rgb(28, 32, 43);
    display: flex;
    flex-direction: row;
    color: white;
    align-items: center;
    gap: 2vw;
    padding-left: 2vw;
    box-shadow: 0px 5px 10px;
    position: fixed;

    img {
        height: 70%;
        cursor: pointer;
    }
    .link {
        text-decoration: none;
        color: white;
        cursor: pointer;
        &:hover {
            font-weight: bold;
        }
    }
}